<template>
	<el-tooltip class="item" effect="dark" style="margin-left: 10px;color: #aaa;font-size: 16px;display: inline;"
		:content="tip" placement="top">
		<span class="el-icon-question"></span>
	</el-tooltip>
</template>

<script>
	// beforeCreate created beforeMount mounted 都不会触发。

	export default {
		data() {
			return {

			}
		},
		props: {
			tip: {
				type: String,
				default: () => {
					return ''
				}
			}
		},
		methods: {

		},
		components: {

		},
		activated: {
			// keep-alive组件 激活 时调用。
			//该钩子在服务器端渲染期间不被调用。

		},
		deactivated: {
			//keep-alive组件  停用 时调用。
			//该钩子在服务器端渲染期间不被调用。
		},
		watch: {
			// data(newValue, oldValue) {

			// }
		},
		filters: {
			// fnName: function(value) {
			// 	return value;
			// }
		},
		beforeCreate() {

		},
		created() {

		},
		beforeMount() {

		},
		mounted() {

		},
		beforeUpdate() {

		},
		updated() {

		},
		beforeDestroy() {

		},
		destroyed() {

		},
		computed: {
			// name() {
			// 	return this.data
			// }
		},

	}
</script>

<style lang="less" scoped>

</style>
